<template>
    <v-menu :close-on-content-click="true" offset-x offset-y v-if="AmoCrmHrefInfo">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-if="AmoCrmHrefInfo"
                class="svg-icon"
                v-bind="attrs"
                v-on="on"
                :style="`width:${btnWidth}px;height:${btnHeight}px`"
            >
                <v-icon :size="size">{{ '$vuetify.icons.amoCrm' }}</v-icon></v-btn
            >
        </template>
        <v-card min-width="198px" :style="{ maxHeight: '450px' }">
            <v-list dense>
                <v-list-item
                    @click.prevent="openInNewTab(item.link)"
                    v-for="item in links"
                    class="text-btn-wrapper"
                    :key="item.id + item.type"
                >
                    <a target="_blank" :href="item.link" class="mx-3 my-2"> {{ item.text }}</a>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>
<script>
import { openInNewTab } from '@root/src/utils'
import { CONNECTOR_TYPES } from '@root/src/vars/general'
import { getClientRefFromParsed } from '../chisai/getClientsBack/helpers'

const TYPES_ORDER = {
    leads: 1,
    contacts: 2,
    companies: 3,
}

export default {
    name: 'AmoCrmLink',
    components: {},
    props: {
        hrefInfo: {
            type: Array,
            default: () => [],
        },
        textMessage: {
            type: String,
            default: undefined,
        },
        size: {
            type: Number,
            default: 18,
        },
        btnWidth: {
            type: Number,
            default: 36,
        },
        btnHeight: {
            type: Number,
            default: 36,
        },
    },
    data: () => ({}),
    computed: {
        AmoCrmHrefInfo() {
            return this?.hrefInfo?.find(
                el =>
                    el.connectorType === CONNECTOR_TYPES.amoCrm ??
                    el.connectorType === CONNECTOR_TYPES.amoCrmFull
            )
        },
        links() {
            return this.AmoCrmHrefInfo.link
                .map(el => {
                    let prefix
                    if (el.type === 'leads') prefix = 'Сделка'
                    if (el.type === 'contacts') prefix = 'Контакт'
                    if (el.type === 'companies') prefix = 'Компания'
                    return {
                        id: el.id,
                        type: el.type,
                        text: `${prefix}: «${el.name}»`,
                        link: `https://${this.AmoCrmHrefInfo.subdomain}/${el.type}/detail/${el.id}?compact=yes`,
                    }
                })
                .sort((a, b) => {
                    const orderA = TYPES_ORDER[a.type]
                    const orderB = TYPES_ORDER[b.type]
                    return orderA - orderB
                })
        },
    },
    methods: { openInNewTab, getClientRefFromParsed },
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'

a
    text-decoration: none !important

*
    scrollbar-width: auto
    scrollbar-color: $light-gray #ffffff

*::-webkit-scrollbar
    width: 14px
*::-webkit-scrollbar-track
    background: #ffffff

*::-webkit-scrollbar-thumb
    background-color: $gray-2
    border-radius: 8px
    border: 4px solid #ffffff
*::-webkit-scrollbar-thumb:hover
    background-color: $gray
</style>
