<template>
    <v-tooltip right open-on-click :open-on-hover="false" v-if="phone" v-model="tooltip">
        <template v-slot:activator="{ on, attrs }">
            <div style="width: fit-content">
                <span class="phone-link" @click="copy" v-bind="attrs" v-on="on" v-if="dispalyedPhone">
                    <TextHighlight :queries="[highlitedText]" :highlightClass="'text-highlight'">{{
                        dispalyedPhone
                    }}</TextHighlight>
                    <v-icon x-small class="ml-1">mdi-content-copy</v-icon>
                </span>
            </div>
        </template>
        <span class="white--text">Скопировано</span>
    </v-tooltip>
</template>
<script>
import parsePhoneNumber from 'libphonenumber-js'
import { maskPhone, maskPhonePlain } from '@root/src/utils'
import TextHighlight from 'vue-text-highlight'
import { isNaN } from 'lodash'
export default {
    name: 'PhoneLink',
    components: { TextHighlight },
    props: {
        hrefInfo: {
            type: Array,
            default: () => [],
        },
        highlitedText: {
            type: String,
            default: '',
        },
        hide: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        tooltip: null,
    }),
    computed: {
        phone() {
            const telHrefInfo = this?.hrefInfo?.find(el => el.connectorType === 'tel')
            return telHrefInfo ? telHrefInfo.phone : null
        },
        parsedPhone() {
            return parsePhoneNumber(this.phone)
        },
        formattedPhone() {
            if (!this.parsedPhone) return ''
            if (this.parsedPhone.countryCallingCode === '7') {
                return (
                    '+7' +
                    this.parsedPhone
                        .formatNational()
                        .substring(1)
                        .replaceAll('(', '')
                        .replaceAll(')', '')
                )
            } else {
                return this.parsedPhone.formatInternational()
            }
        },
        dispalyedPhone() {
            return this.phone ? (this.hide ? this.hidePhone() : this.formattedPhone) : null
        },
    },
    methods: {
        hidePhone() {
            const end = this.formattedPhone.length - 3
            const start = end - 7
            const indexesToHide = Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx)
            return this.formattedPhone
                .split('')
                .map((char, i) => {
                    if (indexesToHide.includes(i) && !isNaN(parseInt(char))) {
                        return 'x'
                    } else {
                        return char
                    }
                })
                .join('')
        },

        copy() {
            this.copyLink()
            setTimeout(() => {
                this.tooltip = false
            }, 1000)
        },
        copyLink() {
            const el = document.createElement('textarea')
            el.value = this.parsedPhone.number
            el.setAttribute('readonly', '')
            el.style.position = 'absolute'
            el.style.left = '-9999px'
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)
        },
    },
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
.phone-link
    cursor: pointer
</style>
