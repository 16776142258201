<template>
    <v-btn
        v-if="sonlineHrefInfo"
        class="svg-icon"
        icon
        @click="openInNewTab(getClientRefFromParsed(sonlineHrefInfo))"
        :style="`width:${btnWidth}px;height:${btnHeight}px`"
    >
        <v-icon :size="size" color="main">{{ '$vuetify.icons.sonline' }}</v-icon></v-btn
    >
</template>

<script>
import { openInNewTab } from '@root/src/utils'
import { getClientRefFromParsed } from '../chisai/getClientsBack/helpers'
import { CONNECTOR_TYPES } from '@root/src/vars/general'

export default {
    components: {},
    props: {
        hrefInfo: {
            type: Array,
            default: () => [],
        },
        size: {
            type: Number,
            default: 18,
        },
        btnWidth: {
            type: Number,
            default: 36,
        },
        btnHeight: {
            type: Number,
            default: 36,
        },
    },
    data: () => ({}),
    computed: {
        sonlineHrefInfo() {
            return this?.hrefInfo?.find(el => el.connectorType === CONNECTOR_TYPES.sonline)
        },
    },
    methods: { openInNewTab, getClientRefFromParsed },
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
</style>
