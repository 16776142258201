<template>
    <div class="chat-link-wrapper">
        <v-btn
            icon
            v-if="chatClientsHrefInfo && !chatLinkSettings.hide"
            class="svg-icon"
            @click.stop="openLink()"
            :style="`width:${btnWidth}px;height:${btnHeight}px`"
        >
            <v-icon :size="size">{{ '$vuetify.icons.chat' }}</v-icon>
        </v-btn>
        <span v-show="isMessageShow" class="copy-to-buffer-message">Текст активации был скопирован</span>
    </div>
</template>
<script>
import { openInNewTab } from '@root/src/utils'
import { getClientRefFromParsed } from '../chisai/getClientsBack/helpers'
import messages from '@dataheroes/messages'
import { CONNECTOR_TYPES } from '@root/src/vars/general'
import * as t from '@root/src/store/chisai/GCB2/actionTypes'
import { mapStores } from 'pinia'
import { useGcb2Store } from '@root/src/store/stores/gcb2/gcb2'
import { ref } from 'vue'
import { getApp } from '@root/src/api/networkFixer'

export default {
    name: 'ChatLink',
    props: {
        hrefInfo: {
            type: Array,
            default: () => [],
        },
        chatLinkSettings: {
            type: Object,
            default: () => ({}),
        },
        textMessage: {
            type: String,
            default: undefined,
        },
        size: {
            type: Number,
            default: 18,
        },
        btnWidth: {
            type: Number,
            default: 36,
        },
        btnHeight: {
            type: Number,
            default: 36,
        },
        manualCommunication: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        isMessageShow: ref(false),
        isTelegram: ref(false),
        isWhatsapp: ref(false),
    }),
    computed: {
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        gcb2Connector() {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        currentConnector() {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.whatsappBasis)
        },
        user() {
            return this.$store.getters.myUser
        },
        activeManualCommunication() {
            return (
                this.$store.getters[`GCB2/${t.GET_ONE_MANUAL_COMMUNICATION}`](
                    this.activeManualCommunicationId
                ) ?? {
                    activation: {},
                }
            )
        },
        chatClientsHrefInfo() {
            return this?.hrefInfo?.find(el => el.connectorType === 'waLink')
        },
        ...mapStores(useGcb2Store),
        activeManualCommunicationId() {
            return this.gcb2Store.sidebar.communicationId
        },
    },
    mounted() {},
    methods: {
        openInNewTab,
        getClientRefFromParsed,
        setMessageShow(status) {
            this.isMessageShow = status
        },
        processedValue(text) {
            // Фикс для садбара аналитики
            text = text ? text : ''
            let result = []
            const maybeVars = []
            const splitted = []
            let curStrIndex = 0
            const varsIds = messages.vars.vars.map(el => el.aliases.map(alias => `{${alias}}`)).flat()

            const varsRegexp = new RegExp(varsIds.join('|'), 'g')
            const matches = text.matchAll(varsRegexp)
            const matchesArr = [...matches]

            if (matchesArr.length === 0) {
                result = [{ id: 1, val: text }]
                return result
            }
            for (let match of matchesArr) {
                maybeVars.push({
                    val: match[0],
                    startIndex: match.index,
                    endIndex: Number(match.index) + match[0].length,
                })
            }
            maybeVars.forEach((el, i) => {
                splitted.push(text.substring(curStrIndex, el.startIndex))
                splitted.push(el.val)
                if (i === maybeVars.length - 1) {
                    splitted.push(text.substring(el.endIndex, text.length))
                }
                curStrIndex = el.endIndex
            })
            result = splitted.map((el, i) =>
                varsIds.includes(el) ? { id: i, varId: el } : { id: i, val: el }
            )
            return result
        },
        getVarValue(varId) {
            const text = messages.substituteVars({
                text: varId,
                data: {
                    gcb2Connector: this.gcb2Connector,
                    project: this.project,
                    userId: this.user.sub,
                    communication: this.activeManualCommunication,
                },
            }).text
            return text
        },
        async openLink() {
            const { data, error } = await getApp({
                connectorId: this.currentConnector.connectorId,
            })
            console.log('results', data)

            // Определяем, какой конкретно бот подключен, wa или tg
            if (data.data.channel['wApi-telegram']) {
                this.isTelegram = true
            } else {
                this.isWhatsapp = true
            }
            console.log('isWa', this.isWhatsapp)
            console.log('isTg', this.isTelegram)

            let templateLink = ''

            // Формируем ссылки для веб версии
            if (this.isWhatsapp) {
                templateLink = 'https://web.whatsapp.com/send/?phone={{phone}}&text={{text}}'
            } else {
                templateLink =
                    'https://web.telegram.org/k/#?tgaddr=tg://resolve%3Fphone%3D{{phone}}%26text%3D{{text}}'
            }

            // Формируем ссылки для десктоп версии
            if (this.gcb2Connector?.whatsappLinkSettings?.template && this.isWhatsapp) {
                templateLink = 'https://api.whatsapp.com/send/?phone={{phone}}&type=phone_number&app_absent=0'
            } else if (this.gcb2Connector?.whatsappLinkSettings?.template && this.isTelegram) {
                templateLink = 'tg://resolve?phone={{phone}}&text={{text}}'
            }

            const chatLinkSettings = {
                appType: this.isTelegram ? 'tg' : 'wa',
                template: templateLink,
                textType: 'activationScript',
                hide: false,
            }

            const currentTextProcessedValue = this.processedValue(this.textMessage)
            let currentMessage = ''
            for (let item of currentTextProcessedValue) {
                if (!item.varId) currentMessage += item.val
                else currentMessage += this.getVarValue(item.varId)
            }

            const href = this.getClientRefFromParsed(
                this.chatClientsHrefInfo,
                chatLinkSettings,
                currentMessage
            )

            if (this.gcb2Connector.copyToBuffer) {
                if (!currentMessage) {
                    currentMessage = 'Добрый день!'
                }

                navigator.clipboard.writeText(currentMessage)
                this.setMessageShow(true)
                setTimeout(() => {
                    this.setMessageShow(false)
                    this.openInNewTab(href)
                    if (this.chatLinkSettings.doubleRedirect) {
                        setTimeout(() => this.openInNewTab(href), 250)
                    }
                }, 1000)
            } else {
                this.openInNewTab(href)
            }
        },
    },
}
</script>

<style lang="sass">
@import '@/vars.sass'
.chat-link-wrapper
    position: relative
    svg
        overflow: visible
.copy-to-buffer-message
    display: flex
    align-items: center
    justify-content: center
    background-color: #586577
    color: #fff
    opacity: 0.8
    border-radius: 10px
    position: absolute
    top: 30px
    left: -140px
    width: 150px
    padding: 5px 10px
    height: 60px
    border: 1px solid #465468
    z-index: 9999 !important
</style>
