import { render, staticRenderFns } from "./YClientsLink.vue?vue&type=template&id=92f2aa76&scoped=true"
import script from "./YClientsLink.vue?vue&type=script&lang=js"
export * from "./YClientsLink.vue?vue&type=script&lang=js"
import style0 from "./YClientsLink.vue?vue&type=style&index=0&id=92f2aa76&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92f2aa76",
  null
  
)

export default component.exports